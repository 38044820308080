@import 'npm:simple-keyboard/build/css/index.css';

@tailwind base;
@tailwind components;
@tailwind utilities;



.app{
    width: 1080px;
    height: 1920px;
    margin: auto;
}

.seo{
    text-indent: -9999px;
    overflow: hidden;
}

.nav-prev:disabled, .nav-next:disabled{
    opacity: 0.5;
}

.screen{
    @apply absolute top-0 left-0 w-full h-full;
}

.screen-0{
    background: url('./images/screen-1.jpg') no-repeat center top;
    background-size: cover;
}

.screen-1{
    background: url('./images/background.jpg') no-repeat center top;
    background-size: cover;
}

.screen-1 .header{
    background: url('./images/header-screen-2.png') no-repeat center top;
}

.screen-2{
    background: url('./images/background.jpg') no-repeat center top;
    background-size: cover;
}

.screen-2 .header{
    background: url('./images/header-screen-3.png') no-repeat center top;
}

.screen-3{
    background: url('./images/background.jpg') no-repeat center top;
    background-size: cover;
}

.screen-3 .header{
    background: url('./images/header-screen-4.png') no-repeat center top;
}

.screen-4{
    background: url('./images/end.jpg') no-repeat center top;
    background-size: cover;
}

.poster{
    width: 570px;

    @apply relative;
}

.poster .stamp{
    @apply absolute top-0 left-0 w-full h-full;
}

.poster-1{
    background: url('./images/background-1.jpg') no-repeat center top;
    background-size: cover;
}

.poster-2{
    background: url('./images/background-2.jpg') no-repeat center top;
    background-size: cover;
}

.poster-3{
    background: url('./images/background-3.jpg') no-repeat center top;
    background-size: cover;
}

.poster-4{
    background: url('./images/background-4.jpg') no-repeat center top;
    background-size: cover;
}

.poster-5{
    background: url('./images/background-5.jpg') no-repeat center top;
    background-size: cover;
}

.poster-6{
    background: url('./images/background-6.jpg') no-repeat center top;
    background-size: cover;
}

.poster-1 .stamp{
    background: url('./images/stamp-1.png') no-repeat center top;
    background-size: cover;
}

.poster-2 .stamp{
    background: url('./images/stamp-2.png') no-repeat center top;
    background-size: cover;
}

.poster-3 .stamp{
    background: url('./images/stamp-3.png') no-repeat center top;
    background-size: cover;
}

.poster-4 .stamp{
    background: url('./images/stamp-4.png') no-repeat center top;
    background-size: cover;
}

.poster-5 .stamp{
    background: url('./images/stamp-5.png') no-repeat center top;
    background-size: cover;
}

.poster-6 .stamp{
    background: url('./images/stamp-6.png') no-repeat center top;
    background-size: cover;
}

.poster-phrase{
    @apply absolute top-0 left-0 w-full h-full;
    
}
.poster-phrase-1{
    background: url('./images/phrase-1.png') no-repeat center top;
    background-size: cover;
}

.poster-phrase-2{
    background: url('./images/phrase-2.png') no-repeat center top;
    background-size: cover;
}

.poster-phrase-3{
    background: url('./images/phrase-3.png') no-repeat center top;
    background-size: cover;
}

.poster-phrase-4{
    background: url('./images/phrase-4.png') no-repeat center top;
    background-size: cover;
}

.poster-phrase-5{
    background: url('./images/phrase-5.png') no-repeat center top;
    background-size: cover;
}

.poster-phrase-6{
    background: url('./images/phrase-6.png') no-repeat center top;
    background-size: cover;
}


.poster-footer{
    @apply absolute top-0 left-0 w-full h-full;

    background: url('./images/footer-nosalive.png') no-repeat center top;
    background-size: cover;
}

.footer{
    background: url('./images/footer.png') no-repeat center bottom;
}